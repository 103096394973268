import React from 'react'
import LinkWrap from 'components/LinkWrap'
import { trFunction } from 'utils/functions'
import Layout from 'components/layout'
import ContentBlock from 'components/contentBlock'
import { graphql } from 'gatsby'

const Page = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const page = data.thisPage
  return (
    <Layout>
      <div id="content">
        {page.contentBlocks.map((block, i) => (
          <ContentBlock key={i} order={i} block={block} data={data} />
        ))}
        <div
          className="wrap"
          style={{ textAlign: 'center', padding: '50px 0' }}
        >
          <LinkWrap to="/contact" className="button">
            {tr('CONTACT_US')}
          </LinkWrap>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "bespoke-landing" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      contentBlocks {
        id
        slug
        template
        label
        title
        subtitle
        textPosition
        content {
          id
          childMarkdownRemark {
            html
          }
        }
        imagesFull {
          ...GatsbyImage
        }
        imagesFullMobile {
          ...GatsbyImage
        }
        imagesFullLink
        buttonLink
        imageSingle {
          ...GatsbyImage
        }
        video {
          wistiaCode
          optimizedFiles {
            file {
              url
              fileName
            }
          }
        }
        slides {
          images {
            ...GatsbyImage
          }
          caption
        }
      }
    }
    collaborations: allContentfulCollaborations(
      sort: { fields: [order], order: ASC }
      limit: 4
    ) {
      edges {
        node {
          title
          slug
          designedBy
          imageMain {
            ...GatsbyImage
          }
        }
      }
    }
  }
`
